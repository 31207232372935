import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import microfrontendLayoutCheckout from "./microfrontend-layout-checkout.html";

const domainsCheckout = [
  "localhost",
  "seguroparaviaje.bz",
  // "spv-qa.pervolare.net",
  "spv-qa-temp.pervolare.net",
];
let layoutRoutes = microfrontendLayout;

if (domainsCheckout.includes(location.hostname)) {
  layoutRoutes = microfrontendLayoutCheckout;
}

const routes = constructRoutes(layoutRoutes);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
